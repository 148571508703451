import Base from '@/gikam/js/components/base';
import Gikam from '@/gikam/js/core/gikam-core';
import { I18N } from '@/gikam/i18n/I18N';
import I18NComp from '@/gikam/js/components/I18N';
import Vue from 'vue';
import '../css/home.css';
import '@/gikam/js/core/gikam-global-event';
import { getStore } from './home-store';
import '@/gikam/js/core/xss.js';

import homeVue from '../vue/camellia/home';
import homeDeptVue from '../vue/camellia/homeDept';
import homeDeptRoleVue from '../vue/camellia/homeDeptRole';

import arrowDown from '@/gikam/js/components/template/img/arrowDown.vue';
import scroll from '@/gikam/js/components/template/scroll/vue/scroll';
import ErrorAlert from '@/gikam/js/components/errorAlert/js/errorAlert';

Gikam.ignoreErrorUrl = ['logins/action/check-account', '/open/core/module/mdm/user-passwords/userId'];

Vue.component('arrowDown', arrowDown);
Vue.component('scroll', scroll);

Gikam.component.I18N = I18NComp;
Gikam.component.ErrorAlert = ErrorAlert;

const defaultOptions = {
    // 配置的logo标志
    logo: void 0,
    // 国际化文件
    I18NUrl: void 0,
    // 皮肤主题，选择范围green/blue
    skin: 'blue',
    style: 'camellia',
    // 是否选择部门和角色
    selectDeptAndRole: false,
    // 配置系统小图标
    xIconUrl: void 0,
    // 系统名称
    system: void 0,
    // 是否记住登录用户名
    userRemember: false,
    // 默认语言
    locale: 'zh-cn',
    // 是否显示中英文切换
    i18n: false,
    // 是否使用浏览器记住密码
    nativePassword: false,
    // 是否找回密码
    retrievePassword: '1',
    // 登陆页标语
    philosophy: '',
    lottieWebUrl: '',
    defaultFocusStatus: false,
    // 找回密码点击
    onRetrievePasswordClick: null
};

export default class HomePage extends Base {
    constructor(props) {
        super();
        this.initialize(props, defaultOptions).init();
    }

    async init() {
        if (!localStorage.getItem('locale')) {
            localStorage.setItem('locale', this.options.locale);
        }
        this.locale = localStorage.getItem('locale');
        await I18N.init(this.locale);
        await I18NComp.initMessage(this.locale);
        this.initSystemInfo();
        if (location.search.indexOf('?mobile=app') > -1) {
            this.createScanQRModel();
            return;
        }
        const businessPage = ['passwordRecovery'];
        const pathname = document.location.hash.slice(1);

        if (businessPage.includes(pathname)) {
            this.createBusinessPage(pathname);
        } else if (this.options.style === 'camellia') {
            pathname === 'resetPassword' && this.createResetPasswordPage();
            this.model = this.createViewModel(pathname);
        } else {
            pathname === 'resetPassword' && this.createResetPasswordPage();
            await this.createNewPage(pathname);
        }
    }

    initSystemInfo() {
        document.title = I18NComp.prop(this.options.title);
        if (this.options.xIconUrl) {
            const linkDom = document.createElement('link');
            Gikam.setDomAttribute(linkDom, {
                rel: 'icon',
                href: this.options.xIconUrl
            });
            document.head.appendChild(linkDom);
        }
    }

    createScanQRModel() {
        const mobileConfig = {
            mobileClient: this.options.mobileClient,
            iphoneQcCode: Gikam.IFM_CONTEXT + this.options.iphoneQrCode,
            androidMobileQcCode: Gikam.IFM_CONTEXT + this.options.androidMobileQrCode,
            androidPadQcCode: Gikam.IFM_CONTEXT + this.options.androidPadQrCode
        };
        new Vue({
            components: { ScanQR: () => import('../vue/scanQR/vue/ScanQR.vue') },
            render() {
                return <ScanQR options={mobileConfig} />;
            }
        }).$mount(Gikam.createDom('div', document.body));
    }

    createViewModel(routePath) {
        const _this = this;
        this.homeModel = new Vue({
            data() {
                return _this.options;
            },

            store: getStore(_this.options),

            render() {
                let home = this.selectDept ? homeDeptVue : this.selectDeptAndRole ? homeDeptRoleVue : homeVue;

                if (this.loginAuthMethod === 'adDomain' || this.loginAuthMethod === 'systemOrAdDomain') {
                    home = homeVue;
                }

                return (
                    <home
                        routePath={routePath}
                        retrievePassword={this.retrievePassword}
                        captcha={this.captcha}
                        customContent={this.customContent}
                        beforeLogin={this.onBeforeLogin}
                        logo={this.logo}
                        remembered={this.nativePassword}
                        skin={this.skin}
                        system={I18NComp.prop(this.system)}
                        userRemember={this.userRemember}
                        description={this.description}
                        locale={_this.locale}
                        loginAuthMethod={this.loginAuthMethod}
                        i18n={this.i18n}
                        showMobileClient={this.mobileClient}
                        defaultFocusStatus={this.defaultFocusStatus}
                    ></home>
                );
            }
        }).$mount(Gikam.createDom('div', document.body));
    }

    async createNewPage(routePath) {
        const getStore = await import('../js/store');
        const style = this.options.style;

        Vue.filter('propI18N', value => Gikam.propI18N(value));
        Vue.directive('bind-background', function(el, bind) {
            if (bind.value) {
                el.style.backgroundImage = `url(${bind.value})`;
            }
        });

        const store = getStore.default(this.options, this.locale, Vue);
        store.commit('changePagePath', routePath);

        this.newModel = new Vue({
            el: Gikam.createDom('div', document.body),
            store: store,
            components: {
                page: () => import(`../vue/${style}/vue/${style}.vue`)
            },
            render() {
                return <page></page>;
            }
        });
    }

    async createBusinessPage(routePath) {
        const logoSrc = this.options.logo;
        new Vue({
            el: Gikam.createDom('div', document.body),
            store: getStore(this.options),
            components: {
                page: () => import(`../vue/businessPage/${routePath}/${routePath}.vue`)
            },
            render() {
                return <page logo={logoSrc}></page>;
            }
        });
    }

    createResetPasswordPage() {
        new Vue({
            el: Gikam.createDom('div', document.body),
            components: {
                page: () => import(`../vue/businessPage/resetPasswordPage/resetPasswordPage.vue`)
            },
            render() {
                return <page></page>;
            }
        });
    }

    static setConfig(props) {
        Object.assign(defaultOptions, props);
        defaultOptions.style = props.pageStyle;
        defaultOptions.skin = props.pageSkin;
        if (props.loginOrg === '1' && props.loginRole === '1') {
            defaultOptions.selectDeptAndRole = true;
        } else if (props.loginOrg === '1') {
            defaultOptions.selectDept = true;
        }
    }
}
