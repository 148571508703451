<template>
    <div class="emc-home-index" :class="[skin, pageClass]">
        <div class="toolbar">
            <div class="mobile" v-if="showMobileClient === '1'" @click="openMobileApp">
                <img src="../component/img/mobile.svg" alt="mobile" />
                {{ $i18n('scanQR.mobile.client') }}
            </div>
            <div v-if="i18n" class="language-switch" @click="languageSwitch">
                <div class="language zh" :class="{ active: locale === 'zh-cn' }">中</div>
                <div class="language en" :class="{ active: locale === 'en-US' }">En</div>
            </div>
        </div>

        <div class="main">
            <div class="bg">
                <div class="sys-name">
                    <div class="title" v-if="system" v-html="propI18N(system)"></div>
                    <div class="title" v-else>
                        云服务
                        <span class="dot"></span>云端 <span class="dot"></span>实验室信息管理系统
                    </div>
                    <div class="sub-title" v-if="description" v-html="description"></div>
                    <div class="sub-title" v-else>Laboratory Information Management System (SW-LIMS V12)</div>
                </div>
            </div>
            <div class="login-container" v-if="routePath !== 'resetPassword'">
                <header class="logo-container" :style="{ backgroundImage: logo ? `url(${logo})` : null }"></header>
                <section class="login-form">
                    <div class="input-group input-username" name="user">
                        <input
                            :placeholder="$i18n('input.username')"
                            v-model="user.name"
                            @focus="showUserNames"
                            @click.stop="showUserNames"
                            @blur="hidePicker"
                            @input="inputHandler"
                            ref="username"
                        />
                    </div>
                    <div class="input-group input-password" name="pw">
                        <passwordInput
                            :placeholder="$i18n('input.password')"
                            :options="{ remembered: remembered }"
                            :showCapsLock="showCapsLock"
                            @enterKeyDown="pawEnterHandle"
                            @input="passowordInputHandle"
                        ></passwordInput>
                    </div>

                    <div class="input-group input-captcha" name="captcha" v-if="captcha === '1'">
                        <input class="captcha-input" :placeholder="$i18n('input.captcha')" v-model="user.captcha" />
                        <div class="captcha-button">
                            <img :src="getCaptchaBase64Url" alt="验证码" :key="srcIndex" />
                            <div class="refresh" @click="srcIndex++"></div>
                        </div>
                    </div>

                    <div class="input-group entry-input-agree" v-html="customContent" v-if="customContent"></div>

                    <button v-show="false"></button>
                </section>
                <login-button
                    class="login-btn"
                    :loading="requesting"
                    @login="login"
                    @changeType="changeType"
                    :loginAuthMethod="loginAuthMethod"
                    :loginType="loginType"
                    :countdown="countdown"
                >
                </login-button>
                <div class="entry-input-tip" v-if="retrievePassword === '1'">
                    <span @click.stop="passwordRecovery">
                        {{ $i18n('password.forget.text') }}
                    </span>
                </div>

                <div class="error-container" v-if="errorMessage">
                    <infoImg v-bind="{ width: '16px', height: '16px' }" class="icon" />
                    {{ errorMessage }}
                </div>
                <div class="login-mask" v-if="requesting"></div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import Gikam from '@/gikam/js/core/gikam-core';
import infoImg from '@/gikam/js/components/template/img/info.vue';
import passwordInput from '@/gikam/js/components/template/fields/password/passwordInput';
import { LoginUtils } from '@/gikam/js/utils/loginUtils';
import { I18N } from '@/gikam/i18n/I18N.js';
import loginButton from '../component/camelliaLoginButton';
import { mapMutations } from 'vuex';

export default {
    props: {
        captcha: {
            type: String,
            default: ''
        },
        customContent: {
            type: String,
            default: ''
        },
        beforeLogin: {
            default: null
        },
        retrievePassword: {
            type: String,
            default: '0'
        },
        routePath: {
            type: String,
            default: ''
        },
        logo: {
            type: String,
            default: null
        },
        skin: {
            type: String,
            default: 'blue'
        },
        loginAuthMethod: {
            type: String,
            default: '0'
        },
        system: String,
        description: String,
        userRemember: Boolean,
        remembered: Boolean,
        locale: String,
        i18n: Boolean,
        showMobileClient: String,
        defaultFocusStatus: Boolean
    },

    components: {
        infoImg,
        passwordInput,
        loginButton
    },

    data() {
        return {
            loginType: this.loginAuthMethod === 'adDomain' || this.loginAuthMethod === 'systemOrAdDomain' ? 1 : 0,
            errorMessage: null,
            requesting: false,
            user: {
                name: null,
                password: null,
                captcha: ''
            },
            captchaBase64: Gikam.IFM_CONTEXT + '/open/security/captcha-images',
            srcIndex: 1,
            countdown: 0,
            inteval: null,
            errorCount: 0,
            showCapsLock: true
        };
    },

    computed: {
        colorValue() {
            const color = { blue: '#007aff', green: '#27986d' };
            return color[this.skin];
        },

        getCaptchaBase64Url() {
            return this.captchaBase64 + '?' + this.srcIndex;
        },

        pageClass() {
            return this.captcha !== '1' && !this.customContent ? 'simple' : '';
        }
    },

    methods: {
        ...mapMutations(['retrievePasswordHandle']),

        changeType(index) {
            this.loginType = index;
        },
        // 密码回车事件
        pawEnterHandle(data) {
            this.user.password = data;
        },

        languageSwitch() {
            I18N.setLocale(this.locale === 'zh-cn' ? 'en-US' : 'zh-cn');
        },

        validate() {
            if (!this.user.name) {
                this.errorMessage = this.$i18n('input.username');
                return false;
            }
            if (!this.user.password) {
                this.errorMessage = this.$i18n('input.password');
                return false;
            }
            if (!this.user.captcha && this.captcha === '1') {
                this.errorMessage = this.$i18n('input.captcha');
                return false;
            }

            if (this.customContent && typeof this.beforeLogin === 'function') {
                const res = this.beforeLogin();
                if (!res || !res.result) {
                    this.errorMessage = res?.message;
                    return false;
                }
            }

            this.errorMessage = null;
            return true;
        },

        async login() {
            if (this.validate() === false || this.errorCount >= 2) {
                return;
            }

            try {
                this.requesting = true;
                await LoginUtils.login(
                    this.user.name,
                    this.user.password,
                    null,
                    null,
                    this.loginType,
                    this.user.captcha
                );
                if (this.userRemember) {
                    let sunwayNames = JSON.parse(window.localStorage.getItem('sunwayNames')) || [];
                    sunwayNames.indexOf(this.user.name) === -1 && sunwayNames.push(this.user.name.trim());
                    window.localStorage.setItem('sunwayNames', JSON.stringify(sunwayNames));
                }
            } catch (error) {
                this.user.captcha && this.srcIndex++;
                this.errorMessage = error;
                this.errorCount += 1;
                if (this.errorCount >= 2) {
                    this.startCountdown();
                }
            } finally {
                this.requesting = false;
            }
        },

        startCountdown() {
            this.countdown = 5;
            this.inteval = setInterval(() => {
                this.countdown -= 1;
                if (this.countdown === 0) {
                    this.errorCount = 0;
                    clearInterval(this.inteval);
                }
            }, 1000);
        },

        inputHandler() {
            if (this.inteval) {
                clearInterval(this.inteval);
                this.countdown = 0;
                this.errorCount = 0;
            }
        },

        passowordInputHandle(value) {
            this.user.password = value;
            this.inputHandler();
        },

        //显示本地存储过的用户名
        showUserNames(e) {
            if (!this.userRemember) return;
            if (Gikam.userPicker) return;
            const sunwayNames = JSON.parse(window.localStorage.getItem('sunwayNames'));
            if (Gikam.isEmpty(sunwayNames)) return;
            const _this = this;
            Gikam.userPicker = new Vue({
                el: Gikam.createDom('div', document.body),
                components: {
                    userPicker: () => import('../component/userPicker')
                },
                computed: {
                    userNames() {
                        if (!_this.user.name) {
                            return sunwayNames;
                        }
                        return sunwayNames.filter(user => user.indexOf(_this.user.name) > -1);
                    }
                },
                render() {
                    return (
                        <user-picker
                            targetDom={e.target}
                            userNames={this.userNames}
                            onenterName={name => (_this.user.name = name)}
                        />
                    );
                }
            });
        },

        //隐藏用户名提示框
        hidePicker() {
            setTimeout(() => {
                if (!Gikam.userPicker) return;
                Gikam.userPicker.$destroy();
                Gikam.removeDom(Gikam.userPicker.$el);
                Gikam.userPicker = null;
            }, 5e2);
        },

        propI18N(text) {
            return Gikam.propI18N(text);
        },

        openMobileApp() {
            const url = window.location.href + '?mobile=app';
            window.open(url);
        },

        passwordRecovery() {
            this.retrievePasswordHandle();
        }
    },

    mounted() {
        if (this.defaultFocusStatus) {
            this.$refs.username.focus();
        }
        document.addEventListener('keydown', event => {
            event.keyCode === 13 && this.login();
        });
    }
};
</script>

<style scoped lang="scss">
input:focus {
    outline: none;
}

.main {
    height: 384px;
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    top: 50%;
    margin-top: -199px;
    .bg {
        width: 100%;
        background-color: #0a4196;
        height: 256px;
        display: flex;
        align-items: center;

        .sys-name {
            padding-left: 96px;
            .title {
                font-size: 30px;
                color: #fff;
                display: flex;
                align-items: center;
                .dot {
                    background-color: #fff;
                    border-radius: 50%;
                    width: 10px;
                    height: 10px;
                    margin: 0 5px;
                }
            }
            .sub-title {
                font-size: 14px;
                color: rgba(255, 255, 255, 0.3);
                margin-top: 24px;
            }
        }
    }
}

.login-container {
    width: 400px;
    padding: 32px 0;
    z-index: 1;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.15);
    position: absolute;
    right: 18%;
    top: 192px;
    transform: translateY(-50%);
    .logo-container {
        height: 80px;
        background-image: url('../../../img/logo-blue.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50%;
    }

    .login-form {
        width: 328px;
        margin: 32px auto 10px;
        overflow: hidden;
        .input-group {
            height: 40px;
            margin-bottom: 32px;
            position: relative;
            &::after {
                content: '';
                position: absolute;
                left: 15px;
                top: 50%;
                margin-top: -7px;
                width: 14px;
                height: 14px;
                background-size: 100%;
                background-repeat: no-repeat;
            }
        }
        .input-group.input-username::after {
            background-image: url('./img/blur-entry-input-account.svg');
        }

        .input-group.input-password::after {
            background-image: url('./img/blur-entry-input-password.svg');
        }

        .input-group.input-captcha::after {
            background-image: url('./img/blur-entry-input-captcha.svg');
        }

        .input-group.input-captcha {
            margin-bottom: 16px;
            display: flex;
            justify-content: space-between;
            .captcha-input {
                flex: 1;
            }
            .captcha-button {
                padding-right: 21px;
                margin-left: 8px;
                border: 1px solid #d9d9d9;
                overflow: hidden;
                border-radius: 4px;
                position: relative;
                > img {
                    width: 100px;
                    height: 42px;
                    margin: -3px 0 0 -2px;
                }
                .refresh {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    width: 25px;
                    background: #fff;
                    border-left: 1px solid #d9d9d9;
                    cursor: pointer;
                    &::after {
                        content: '';
                        position: absolute;
                        right: 5px;
                        top: 50%;
                        margin-top: -7px;
                        width: 14px;
                        height: 14px;
                        background-size: 100%;
                        background-image: url('./img/blur-entry-input-captcha-refresh.svg');
                        background-repeat: no-repeat;
                    }
                }
            }
        }

        .input-group.entry-input-agree {
            margin-bottom: 16px;
            display: block;
            height: auto;
        }

        input {
            width: 100%;
            height: 100%;
            border: none;
            background-color: transparent;
            flex: 1;
            font-size: 14px;
            color: #666;
            padding-left: 40px;
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            &:focus {
                border-color: rgba(0, 122, 255, 0.5);
            }
        }
    }

    .login-btn {
        width: 340px;
        height: 42px;
        line-height: 42px;
    }

    .entry-input-tip {
        width: 340px;
        height: 20px;
        line-height: 20px;
        text-align: right;
        margin: 16px auto 0 auto;
        span {
            font-family: PingFangSC-Regular;
            font-size: 12px;
            color: #007aff;
            opacity: 0.85;
            cursor: pointer;
        }
        span:hover {
            opacity: 1;
        }
    }

    .error-container {
        display: flex;
        align-items: center;
        position: absolute;
        top: 100px;
        left: 36px;
        right: 36px;
        height: 25px;
        background-color: rgba(255, 201, 201, 0.2);
        border: 1px solid #ff6e6e;
        color: #ff6e6e;
        font-size: 12px;
        .icon {
            margin-right: 8px;
        }
    }

    .login-mask {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 10;
    }
}

/* 换肤-绿色 */
.emc-home-index.green .language-switch > .language.active {
    color: #fff;
}

.emc-home-index.green .language-switch > .language.active,
.emc-home-index.green .bg,
.emc-home-index.green .login-btn {
    background-color: #27986d;
}

.emc-home-index.green .login-btn .user-container {
    border-color: #27986d;
}

//输入框样式
@mixin placeholder {
    color: rgba(0, 0, 0, 0.15);
    font-size: 14px;
}
.input-group {
    input::-webkit-input-placeholder {
        @include placeholder;
    }

    input::-moz-input-placeholder {
        @include placeholder;
    }

    input:-ms-input-placeholder {
        @include placeholder;
    }

    input::-ms-clear {
        display: none;
    }
    ::v-deep {
        .password-input {
            input {
                padding-left: 40px;
            }
        }
        ::-webkit-input-placeholder {
            @include placeholder;
        }
        :-moz-placeholder {
            @include placeholder;
        }
        ::-moz-placeholder {
            @include placeholder;
        }
        :-ms-input-placeholder {
            @include placeholder;
        }
        .select .select-container .placeholder {
            color: rgba(0, 0, 0, 0.1);
            font-size: 14px;
        }
        input::-ms-clear {
            display: none;
        }
    }
}

//根据不同的功能适配样式
.simple .login-container {
    padding: 40px 0;
    .login-form {
        margin: 40px auto 16px;
        .input-group {
            margin: 20px 0;
        }
    }
}

/* 移动端适配 */
@media screen and (max-width: 1200px) and (min-width: 1000px) {
    .sys-name .title {
        font-size: 18px;
    }
}

@media screen and (max-width: 1000px) {
    .login-container {
        position: fixed;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
    }
    .sys-name {
        width: 100%;
        padding: 0;
        position: absolute;
        bottom: -175px;
    }
    .sys-name .title {
        font-size: 20px;
        color: #d9d9d9;
        display: flex;
        justify-content: center;
    }
    .sys-name .sub-title {
        color: #d9d9d9;
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 420px) {
    .main {
        width: 100%;
        margin-top: 0;
    }

    .bg {
        display: none;
    }

    .login-container {
        width: 100%;
        padding: 0 16px;
        box-shadow: none;
    }

    .entry-input-tip,
    .login-btn,
    .login-form {
        width: 90%;
    }

    .login-form .input-group input {
        min-width: 100px;
    }

    .sys-name {
        display: none;
    }

    .logo-container {
        background-size: 50%;
    }
}
</style>
