import Vuex from 'vuex';
import Gikam from 'gikam';
import Vue from 'vue';

Vue.use(Vuex);

export function getStore(options) {
    return new Vuex.Store({
        state: {},
        mutations: {
            retrievePasswordHandle() {
                const { onRetrievePasswordClick } = options;
                if (onRetrievePasswordClick) {
                    onRetrievePasswordClick.call();
                } else {
                    window.open(Gikam.IFM_CONTEXT + '/#passwordRecovery');
                }
            }
        }
    });
}
